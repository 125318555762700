import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { BInquirer } from 'app/modules/data-model/inquirer/inquirer';
import { Helpers } from '@mi-tool/utils/helpers';

@Component({
  selector: 'app-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss'],
})
export class GenericDialogComponent {
  response = new Subject<boolean>();
  title: string;
  message: string;
  additionalMessage: string;
  confirmationQuestion: string;
  cancelButtonLabel: string;
  saveButtonLabel: string;
  isCancelAvailable: boolean;
  checkboxLabel: string;
  checkboxValue: boolean = false;
  showInquirersComparisonContainer: boolean = false;
  firstInquirer: BInquirer = null;
  secondInquirer: BInquirer = null;
  tipText: string;

  constructor(public translateService: TranslateService, private helpers: Helpers) {}

  saveResponse(response): void {
    this.response.next(response);
  }

  static Builder = class {
    private readonly ref: MatDialogRef<GenericDialogComponent>;
    private instance: GenericDialogComponent;
    private translate: TranslateService;

    constructor(modalService: MatDialog, config?: MatDialogConfig) {
      this.ref = modalService.open(GenericDialogComponent, config);
      this.instance = this.ref.componentInstance;
      this.translate = this.instance.translateService;
    }

    title(translationKey: string, params?: Object): GenericDialogComponent.Builder {
      this.instance.title = translationKey && this.translate.instant(translationKey, params);
      return this;
    }

    message(translationKey: string, params?: Object): GenericDialogComponent.Builder {
      this.instance.message = translationKey && this.translate.instant(translationKey, params);
      return this;
    }

    additionalMessage(transitionKey: string, params?: Object): GenericDialogComponent.Builder {
      this.instance.additionalMessage =
        transitionKey && this.translate.instant(transitionKey, params);
      return this;
    }

    confirmationQuestion(translationKey: string): GenericDialogComponent.Builder {
      this.instance.confirmationQuestion = this.translate.instant(translationKey);
      return this;
    }

    checkboxLabel(translationKey: string): GenericDialogComponent.Builder {
      this.instance.checkboxLabel = this.translate.instant(translationKey);
      return this;
    }

    cancelLabel(translationKey: string): GenericDialogComponent.Builder {
      this.instance.isCancelAvailable = true;
      this.instance.cancelButtonLabel = this.translate.instant(translationKey);
      return this;
    }

    saveLabel(transitionKey: string): GenericDialogComponent.Builder {
      this.instance.saveButtonLabel = this.translate.instant(transitionKey);
      return this;
    }

    closeOnButton(): GenericDialogComponent.Builder {
      this.instance.response.subscribe(() => this.ref.close());
      return this;
    }

    onSave(callback: Function): GenericDialogComponent.Builder {
      this.instance.response.subscribe((resp) => resp && callback());
      return this;
    }

    getSaveSubject(): Subject<void> {
      const sub = new Subject<void>();
      this.instance.response.subscribe((resp) => resp && sub.next(undefined));
      return sub;
    }

    getSaveSubjectWithCheckboxVal(): Subject<boolean> {
      const sub = new Subject<boolean>();
      this.instance.response.subscribe((resp) => resp && sub.next(this.instance.checkboxValue));
      return sub;
    }

    getRef(): MatDialogRef<GenericDialogComponent> {
      return this.ref;
    }

    translateJoin(translationKeys: string[]): string {
      const translated = this.instance.helpers.translateMany(translationKeys);
      return this.instance.helpers.arrayOfItemsToText(translated, false);
    }
  };

  static openSplitInteractionConfirm(
    modalService: MatDialog
  ): MatDialogRef<GenericDialogComponent> {
    return new GenericDialogComponent.Builder(modalService)
      .title('SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_QUESTION')
      .message('SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_MESSAGE')
      .checkboxLabel('COPY_ANSWER_TO_NEW_INTERACTION')
      .cancelLabel('CANCEL')
      .saveLabel('CONFIRM')
      .getRef();
  }

  static openSetNewPassword(modalService: MatDialog): Subject<boolean> {
    const confirmation = new Subject<boolean>();
    new GenericDialogComponent.Builder(modalService, { disableClose: true, autoFocus: 'dialog' })
      .message('ERASED_PASSWORD_AFTER_ACCOUNT_REACTIVATION')
      .saveLabel('SET_PASSWORD')
      .closeOnButton()
      .onSave(() => confirmation.next(true));
    return confirmation;
  }

  static openSharedSearchMsg(modalService: MatDialog): Subject<boolean> {
    const confirmation = new Subject<boolean>();
    new GenericDialogComponent.Builder(modalService, { disableClose: true, autoFocus: 'dialog' })
      .message('SEARCH_NO_LONGER_EXIST')
      .saveLabel('CLOSE')
      .closeOnButton()
      .onSave(() => confirmation.next(true));
    return confirmation;
  }

  static openApprovalWorkflowsMsg(modalService: MatDialog): MatDialogRef<GenericDialogComponent> {
    // Open approval workflows message when there is no document approval workflow set for the selected team.
    return new GenericDialogComponent.Builder(modalService, {
      disableClose: true,
      autoFocus: 'dialog',
    })
      .title('DOCUMENTS_APPROVAL_WORKFLOW.INFO_DIALOG_TITLE')
      .message('DOCUMENTS_APPROVAL_WORKFLOW.INFO_DIALOG_MSG')
      .saveLabel('CLOSE')
      .getRef();
  }

  static openConfirmationMsg(
    modalService: MatDialog,
    message: string,
    question: string = null,
    tipText: string = null
  ): MatDialogRef<GenericDialogComponent> {
    const ref = modalService.open(GenericDialogComponent, {
      disableClose: true,
      autoFocus: 'dialog',
    });
    ref.componentInstance.isCancelAvailable = true;
    ref.componentInstance.cancelButtonLabel = ref.componentInstance.translateService.instant('NO');
    ref.componentInstance.saveButtonLabel = ref.componentInstance.translateService.instant('YES');
    ref.componentInstance.title = ref.componentInstance.translateService.instant('WARNING');
    ref.componentInstance.message = ref.componentInstance.translateService.instant(message);
    ref.componentInstance.confirmationQuestion =
      question && ref.componentInstance.translateService.instant(question);
    ref.componentInstance.tipText = tipText;
    return ref;
  }

  static openVerifyEmailAddress(modalService: MatDialog): void {
    new GenericDialogComponent.Builder(modalService, { autoFocus: 'dialog' })
      .title('LOGIN_INCORRECT_EMAIL_ADDRESS')
      .message('LOGIN_VERIFY_EMAIL_ADDRESS')
      .saveLabel('CLOSE')
      .closeOnButton();
  }

  static openConfirmationMetadataDialog(
    modalService: MatDialog,
    metadataType: string,
    message: string,
    hasMetadataDeactivation: boolean = true,
    hasDeactivatedRelatedMetadata: boolean = true,
    hasAffectedRelatedTherapeuticArea: boolean = false
  ): MatDialogRef<GenericDialogComponent> {
    const actionType = hasMetadataDeactivation ? 'deactivating' : 'editing';
    const affectedItemsType = hasDeactivatedRelatedMetadata
      ? hasAffectedRelatedTherapeuticArea
        ? 'metadata and therapeutic area'
        : 'metadata'
      : 'therapeutic area';
    return new GenericDialogComponent.Builder(modalService, {
      disableClose: true,
      autoFocus: 'dialog',
    })
      .title('METADATA_ACTION_EDIT_CONFIRM_TITLE', {
        ACTION_TYPE: actionType,
        METADATA_TYPE: metadataType,
        AFFECTED_ITEMS_TYPE: affectedItemsType,
      })
      .message(message)
      .confirmationQuestion('DO_YOU_WANT_TO_CONTINUE')
      .saveLabel('CONFIRM')
      .cancelLabel('CANCEL')
      .getRef();
  }

  static openConfirmationTherapeuticAreaDialog(
    modalService: MatDialog,
    message: string
  ): MatDialogRef<GenericDialogComponent> {
    return new GenericDialogComponent.Builder(modalService, {
      disableClose: true,
      autoFocus: 'dialog',
    })
      .title('THERAPEUTIC_AREA_ACTION_EDIT_CONFIRM_TITLE')
      .message(message)
      .confirmationQuestion('DO_YOU_WANT_TO_CONTINUE')
      .saveLabel('CONFIRM')
      .cancelLabel('CANCEL')
      .getRef();
  }

  static confirmMergeWithDifferentInquirers(
    modalService: MatDialog,
    firstInquirer: BInquirer,
    secondInquirer: BInquirer
  ): MatDialogRef<GenericDialogComponent> {
    const ref = modalService.open(GenericDialogComponent, {
      disableClose: true,
      autoFocus: 'dialog',
    });
    ref.componentInstance.isCancelAvailable = true;
    ref.componentInstance.cancelButtonLabel =
      ref.componentInstance.translateService.instant('CANCEL');
    ref.componentInstance.saveButtonLabel =
      ref.componentInstance.translateService.instant('CONFIRM');
    ref.componentInstance.title = `${ref.componentInstance.translateService.instant(
      'INQUIRERS_COMPARISON_TITLE'
    )}:`;
    ref.componentInstance.confirmationQuestion = ref.componentInstance.translateService.instant(
      'INQUIRERS_COMPARISON_QUESTION'
    );
    ref.componentInstance.firstInquirer = firstInquirer;
    ref.componentInstance.secondInquirer = secondInquirer;
    ref.componentInstance.showInquirersComparisonContainer = true;
    return ref;
  }

  static confirmDeleteAttachment(
    modalService: MatDialog,
    interactionIds: number[] | null
  ): MatDialogRef<GenericDialogComponent> {
    const builder = new GenericDialogComponent.Builder(modalService)
      .cancelLabel('CANCEL')
      .saveLabel('CONFIRM');
    const ref = builder.getRef();
    const translateService = ref.componentInstance.translateService;

    if (interactionIds?.length) {
      ref.componentInstance.message = `${translateService.instant(
        'ATTACHMENT_PRESENT_IN_MULTIPLE_INTERACTIONS'
      )}: ${ref.componentInstance.helpers.arrayOfItemsToText(
        interactionIds.map((id) => id.toString())
      )}<br>${translateService.instant(
        'SELECT_CHECKBOX_TO_DELETE_ATTACHMENT_FROM_ALL_INTERACTIONS'
      )}`;

      builder.checkboxLabel('DELETE_FROM_ALL_INTERACTIONS_MENTIONED_ABOVE');
    } else {
      builder.message('CONFIRM_DELETE_ATTACHMENT');
    }
    return ref;
  }

  static closeInquirer(modalService: MatDialog, unsavedFields: string[]): Subject<boolean> {
    const builder = new GenericDialogComponent.Builder(modalService);
    return builder
      .title('LOST_UNSAVED_CHANGES_ON_INTERACTION_CLOSE_MESSAGE')
      .cancelLabel('LEAVE_WITHOUT_SAVING')
      .saveLabel('KEEP_INQUIRER_DETAILS_OPEN')
      .message('PENDING_CHANGES_MESSAGE', { CHANGES: builder.translateJoin(unsavedFields) })
      .closeOnButton()
      .getRef().componentInstance.response;
  }

  static showMessage(modalService: MatDialog, message: string, title: string = null): void {
    const dialogRef = modalService.open(GenericDialogComponent, { autoFocus: 'dialog' });
    dialogRef.componentInstance.saveButtonLabel =
      dialogRef.componentInstance.translateService.instant('CLOSE');
    if (title) {
      dialogRef.componentInstance.title =
        dialogRef.componentInstance.translateService.instant(title);
    }
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.response.subscribe(() => {
      dialogRef.close();
    });
  }
}

export namespace GenericDialogComponent {
  export type Builder = typeof GenericDialogComponent.Builder.prototype;
}
