import { Injectable } from '@angular/core';
import { BTherapeuticArea } from '../medical-document/medical-document';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { SimplePage } from 'app/common/types';
import { Ajax } from 'app/common/ajax';
import { map } from 'rxjs/operators';
import { isNil, omitBy } from 'lodash';

/*
  'removedProductIds' and 'addedProductIds' - the purpose of this data is to transfer IDs to the backend for easier processing of the products
   associated with the edited therapeutic area (changing their status, creating a history, deactivating any potentially linked topics and categories.
*/
export type TTherapeuticAreaData = {
  id: string;
  name: string;
  description: string;
  image: string;
  parentAreaId: string;
  productIds: string[];
  isActive: boolean;
  removedProductIds?: number[];
  addedProductIds?: number[];
};

export type TTherapeuticAreaDialogData = {
  therapeuticArea: BTherapeuticArea;
};

export type TTherapeuticAreaUpdateResponse = {
  message?: { [key: string]: string | null };
};

export type TTherapeuticAreaParams = {
  pageIndex: number;
  pageSize: number;
  orderBy?: string;
  id?: number;
  name?: string;
  description?: string;
  parentAreaIds?: string;
  productIds?: string;
  isActive?: string;
};

@Injectable({ providedIn: 'root' })
export class TherapeuticAreaRestService {
  private readonly URL: string;

  constructor(private http: HttpClient, private urlResolver: UrlResolverService) {
    this.URL = urlResolver.misApiUrlForPath('/therapeutic-areas/');
  }

  fetchTherapeuticAreasList(
    params: TTherapeuticAreaParams
  ): Observable<SimplePage<BTherapeuticArea>> {
    return this.http
      .get<SimplePage<BTherapeuticArea>>(this.URL, {
        ...Ajax.X_NO_LOADING_OPTIONS,
        params: omitBy(params, isNil),
      })
      .pipe(
        map((response) => {
          return { ...response, records: BTherapeuticArea.fromRestArray(response.records) };
        })
      );
  }

  edit(data: TTherapeuticAreaData): Observable<TTherapeuticAreaUpdateResponse> {
    return this.http.put<TTherapeuticAreaUpdateResponse>(this.URL, omitBy(data, isNil));
  }

  create(data: TTherapeuticAreaData): Observable<void> {
    return this.http.post<void>(this.URL, omitBy(data, isNil));
  }

  buildImageUrl(therapeuticAreaId: number): string {
    return this.urlResolver.apiUrlForPath([
      'api-rest',
      'therapeutic',
      'image',
      `${therapeuticAreaId}`,
    ]);
  }
}
